<template>
  <div class="about">
    <h1>Longevity</h1>    
    <table>
      <thead>
        <tr>
          <th>Age</th>
          <th>Value</th>
          <th v-for="c in 25" :key="c">AUM {{ c }} years</th>
        </tr>
      </thead>        
      <tbody>  
        <tr v-for="line in aum" :key="line">
          <td>{{ line['0_age'] }}</td>
          <td>{{ line['1_funds'] }}</td>
          <td v-for="item in line['2_aum_data']" :key="item">{{ item }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import store from "@/store";
  import { onMounted, computed } from "vue";
  export default {
    setup() {

      const aum = computed(() => store.state.aum);

      onMounted(() => store.dispatch("loadLongevity"));

      return {
        aum
      };
    }
  }
</script>
