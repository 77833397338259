<template>
      <h1>FinWin 1.0</h1>
      <p>by GAM FinAI</p>
</template>

<script>
export default {
  name: 'Home'
}
</script>
